import * as React from 'react';
import * as styles from './icon.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import PaletteIcon from '@material-ui/icons/Palette';
import WidgetsIcon from '@material-ui/icons/Widgets';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PhoneIcon from '@material-ui/icons/Phone';
import UserRoundedIcon from '@material-ui/icons/AccountCircle';
import InfoIcon from '@material-ui/icons/Info';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import BulbIcon from '../../images/svg/bulb.inline.svg';
import CardboardBoxIcon from '../../images/svg/cardboard-box.inline.svg';
import DiamondIcon from '../../images/svg/diamond.inline.svg';
import FlexPrintingIcon from '../../images/svg/flex-printing.inline.svg';
import FlexibilityIcon from '../../images/svg/flexibility.inline.svg';
import OffsetPrintingIcon from '../../images/svg/offset-printing.inline.svg';
import PunchingIcon from '../../images/svg/punching.inline.svg';
import ShieldIcon from '../../images/svg/shield.inline.svg';
import StackedBoxesIcon from '../../images/svg/stacked-boxes.inline.svg';
import TransportIcon from '../../images/svg/transport.inline.svg';

// Temporary
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export enum IconType {
  CLOSE = 'CLOSE',
  PALETTE = 'PALETTE',
  BOXES = 'BOXES',
  DOT = 'DOT',
  TRIANGLE = 'TRIANGLE',
  PHONE = 'PHONE',
  USER_ROUNDED = 'USER_ROUNDED',
  INFO = 'INFO',
  CHEVRON_LEFT = 'CHEVRON_LEFT',
  CHEVRON_RIGHT = 'CHEVRON_RIGHT',
  BULB = 'BULB',
  CARDBOARD_BOX = 'CARDBOARD_BOX',
  DIAMOND = 'DIAMOND',
  FLEX_PRINTING = 'FLEX_PRINTING',
  FLEXIBILITY = 'FLEXIBILITY',
  OFFSET_PRINTING = 'OFFSET_PRINTING',
  PUNCHING = 'PUNCHING',
  SHIELD = 'SHIELD',
  STACKED_BOXES = 'STACKED_BOXES',
  TRANSPORT = 'TRANSPORT',
}

const iconList: { [key: string]: React.ReactElement } = {
  [IconType.CLOSE]: <CloseIcon className={styles.icon} />,
  [IconType.PALETTE]: <PaletteIcon className={styles.icon} />,
  [IconType.BOXES]: <WidgetsIcon className={styles.icon} />,
  [IconType.DOT]: <FiberManualRecordIcon className={styles.icon} />,
  [IconType.TRIANGLE]: <PlayArrowIcon className={styles.icon} />,
  [IconType.PHONE]: <PhoneIcon className={styles.icon} />,
  [IconType.USER_ROUNDED]: <UserRoundedIcon className={styles.icon} />,
  [IconType.INFO]: <InfoIcon className={styles.icon} />,
  [IconType.CHEVRON_LEFT]: <ChevronLeftIcon className={styles.icon} />,
  [IconType.CHEVRON_RIGHT]: <ChevronRightIcon className={styles.icon} />,
  [IconType.BULB]: <BulbIcon className={styles.icon} />,
  [IconType.CARDBOARD_BOX]: <CardboardBoxIcon className={styles.icon} />,
  [IconType.DIAMOND]: <DiamondIcon className={styles.icon} />,
  [IconType.FLEX_PRINTING]: <FlexPrintingIcon className={styles.icon} />,
  [IconType.FLEXIBILITY]: <FlexibilityIcon className={styles.icon} />,
  [IconType.OFFSET_PRINTING]: <OffsetPrintingIcon className={styles.icon} />,
  [IconType.PUNCHING]: <PunchingIcon className={styles.icon} />,
  [IconType.SHIELD]: <ShieldIcon className={styles.icon} />,
  [IconType.STACKED_BOXES]: <StackedBoxesIcon className={styles.icon} />,
  [IconType.TRANSPORT]: <TransportIcon className={styles.icon} />,
};

interface IProps {
  name: string;
  className?: string;
}
export const Icon = ({ name, className }: IProps) => (
  <div className={className}>{iconList[name]}</div>
);
